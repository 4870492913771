import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, OverlayTrigger, Placeholder, ProgressBar, Row, Spinner, Tooltip } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import StarsRating from "react-star-rate";

import DstButton from "../../components/DstButton/DstButton";
import { useSession } from "../../contexts/SessionContext";
import ApiService from "../../services/ApiService";
import { getExamStatusIcon, getStatusColor } from "../../services/ExamStatus";
import { formatDateHumanly, formatSecondsMinutely } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate, truncateString } from "../../services/UtilityService";
import {
  downloadEvaluationAttemptNotebook,
  downloadEvaluationFileFromRender,
  extractPreCorrection,
  sendCorrection,
  startCorrection,
} from "./Correction.function";
import { checkAllCorrected } from "./Correction.function";
import "./Correction.scss";
import ExamStatusDropdown from "./components/ExamStatusDropdown";
import HtmlEvaluationRender from "./components/HtmlEvaluationRender";

const Correction = () => {
  const { language } = useSession();
  const { eval_id } = useParams();
  const { user_id } = useSession();
  const navigate = useNavigate();

  const [showOverlay, setShowOverlay] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [loading, setLoading] = useState({
    infos: true,
    render: true,
    submit: false,
    downloadNotebook: false,
  });
  const [evaluationInfos, setEvaluationInfos] = useState<any>({});
  const [evaluationRender, setEvaluationRender] = useState<any>(null);
  const [editorContent, setEditorContent] = useState("");
  const [isCorrectionStarted, setIsCorrectionStarted] = useState(false);
  const [examStatus, setExamStatus] = useState("");
  const [isAllCorrected, setIsAllCorrected] = useState(false);
  const [hasAlreadyCorrector, setHasAlreadyCorrector] = useState(false);
  const [isModifiedComment, setIsModifiedComment] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [correctQuestions, setCorrectQuestions] = useState(0);
  const [answersCorrection, setAnswersCorrection] = useState<Array<any>>([]);
  const [connectedUsers, setConnectedUsers] = useState<Array<any>>([]);
  const [correctorName, setCorrectorName] = useState("");
  const evaluation_attempts_id = eval_id;
  const [showFullComment, setShowFullComment] = useState(false);
  const [score, setScore] = useState<number | undefined>(undefined);
  const [score_base] = useState<number>(20);

  useEffect(() => {
    const socket = ApiService.createSocket("notifications");
    socket.emit("correction-room", { evaluation_attempts_id, user_id, action: true });
    socket.on("user-updated", (data: any) => {
      setConnectedUsers(data.user);
    });

    socket.on("correction-started", (data: any) => {
      if (data.evaluation_attempt_id === Number(evaluation_attempts_id)) {
        if (data.corrector_id !== Number(user_id)) {
          setHasAlreadyCorrector(true);
          setCorrectorName(data.corrector_name);
        }
      }
    });

    socket.on("error", (data: any) => {
      console.error("An error occurred:", data.message);
    });

    return () => {
      socket.emit("correction-room", { evaluation_attempts_id, user_id, action: false });
      socket.disconnect();
    };
  }, [evaluation_attempts_id, user_id, hasAlreadyCorrector]);

  const handleShowOverlay = () => {
    setShowOverlay(true);
    setIsCorrectionStarted(true);
  };

  const normalizeContent = (content: any) => {
    const div = document.createElement("div");
    div.innerHTML = content;
    return div.innerText.trim().replace(/\s+/g, " ");
  };

  useEffect(() => {
    ApiService.get(`evaluations/attempts/${eval_id}/details`)
      .then((resp) => setEvaluationInfos(resp.data.evaluation_attempt_details))
      .catch((err) => console.error(err))
      .finally(() => setLoading((loading) => ({ ...loading, infos: false })));

    ApiService.get(`evaluations/attempts/${eval_id}/render`)
      .then((resp) => setEvaluationRender(resp.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading((loading) => ({ ...loading, render: false })));
  }, [eval_id]);

  useEffect(() => {
    checkAllCorrected(evaluationRender, setIsAllCorrected);
  }, [evaluationRender]);

  useEffect(() => {
    if (evaluationInfos.status === "being_corrected") {
      if (evaluationInfos.corrector.id === user_id) {
        setShowOverlay(true);
        setIsCorrectionStarted(true);
        setHasAlreadyCorrector(false);
      } else {
        setHasAlreadyCorrector(true);
        setCorrectorName(evaluationInfos.corrector.name);
      }
    }
  }, [user_id, evaluationInfos.corrector, evaluationInfos.status]);

  useEffect(() => {
    if (["pre_corrected", "being_corrected"].includes(evaluationInfos.status)) {
      if (evaluationInfos.ai_comment) {
        const prevision = extractPreCorrection(evaluationInfos.ai_comment);

        setExamStatus(prevision.status);
        setEditorContent(prevision.comment);
      }
      if (evaluationInfos.ai_prevision_score) {
        setScore(parseFloat(evaluationInfos.ai_prevision_score.split("/")[0]));
      }
    }
  }, [evaluationInfos]);

  useEffect(() => {
    setIsModifiedComment(
      normalizeContent(extractPreCorrection(evaluationInfos.ai_comment).comment) !== normalizeContent(editorContent)
    );
  }, [editorContent, evaluationInfos.ai_comment]);

  useEffect(() => {
    if (isAllCorrected) {
      if (correctQuestions / totalQuestions >= evaluationInfos.evaluation?.success_ratio) {
        setExamStatus("success");
      } else if (evaluationInfos.number_of_attempts < 3) {
        setExamStatus("repass");
      } else {
        setExamStatus("failed");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correctQuestions, totalQuestions, isAllCorrected]);

  useEffect(() => {
    if (!loading.infos && evaluationInfos?.evaluation?.type === "html" && isCorrectionStarted) {
      if (evaluationRender?.some((item: any) => item.is_correct === null && !item.isEditable)) {
        const updatedEvaluationRender = evaluationRender.map((item: any) => {
          if (item.is_correct === null) {
            return { ...item, isEditable: true };
          }
          return item;
        });

        setEvaluationRender(updatedEvaluationRender);
      }
    }
  }, [loading.infos, evaluationInfos, isCorrectionStarted, evaluationRender]);

  const handleStatusChange = (newStatus: boolean, index: number) => {
    setEvaluationRender((prevEvaluationRender: any) => {
      const updatedItems = [...prevEvaluationRender];
      updatedItems[index] = { ...updatedItems[index], is_correct: newStatus };
      setAnswersCorrection((answersCorrection) => ({
        ...answersCorrection,
        [updatedItems[index].timestamp]: newStatus,
      }));
      return updatedItems;
    });
  };

  const additionalUsers = connectedUsers.length - 3;

  if (!loading.infos && Object.keys(evaluationInfos).length === 0) {
    return (
      <div className="text-center mt-3">
        <h2>{translate(language, "PAGES.EVAL.ERROR.NOT_FOUND")}</h2>
        <DstButton
          value={translate(language, "PAGES.EVAL.BUTTONS.ALL_EVALUATION_ATTEMPTS")}
          btnClass="btn btn-primary"
          link="/evaluations/attempts"
        />
      </div>
    );
  }

  const comment =
    evaluationInfos.user?.remark?.comment || translate(language, "PAGES.EVAL.EXAM_INFO.REMARK.NO_COMMENTS");

  return (
    <div id="correction-page">
      {loading.infos ? (
        <div className="border-bottom">
          <div className="d-flex align-items-center my-3 mx-2 justify-content-between">
            <Placeholder as="h2" animation="glow" className="w-100">
              <Placeholder xs={8} />
            </Placeholder>
            <div className="d-flex">
              <div className="users-show-eval d-flex me-2">
                {[...Array(3)].map((_, index) => (
                  <Placeholder key={index} as="div" animation="glow" className="me-2">
                    <Placeholder.Button className="rounded-circle object-fit-cover clickable size-img-picture" />
                  </Placeholder>
                ))}
              </div>
              <div className="users-show-eval d-flex me-2">
                {[...Array(2)].map((_, index) => (
                  <Placeholder key={index} as="div" animation="glow" className="me-2">
                    <Placeholder.Button className="rounded-circle object-fit-cover clickable size-img-picture" />
                  </Placeholder>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="border-bottom">
          <div className="d-flex align-items-center my-3 mx-2 justify-content-between">
            <div className="d-flex">
              <i
                className="dst-icon-arrow-circle-left h2 me-2 mb-0 blue-font clickable"
                onClick={() => navigate("/evaluations/attempts?status=CORRECTION_PENDING")}
                role="button"
                aria-label="Go to Evaluations page"
              />
              <h2 className="mb-0">{evaluationInfos.evaluation.name}</h2>
            </div>
            <div className="d-flex" id="action-buttons">
              <div className="d-flex users-show-eval">
                {connectedUsers?.slice(0, 3).map((user) => (
                  <OverlayTrigger
                    key={user.id}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${user.id}`}>{user.name}</Tooltip>}
                  >
                    <img
                      src={user.image_url ? user.image_url : "/assets/images/user.jpg"}
                      alt={`${user.name}'s profile`}
                      className="rounded-circle object-fit-cover me-2 clickable size-action-button border"
                      onClick={() => navigate(`/user/${user.id}`)}
                    />
                  </OverlayTrigger>
                ))}
                {additionalUsers > 0 && (
                  <Dropdown align="end" id="additional-users">
                    <Dropdown.Toggle
                      as="div"
                      className="rounded-circle bg-light d-flex align-items-center justify-content-center me-3 clickable size-action-button"
                    >
                      <span className="s-regular">+{additionalUsers}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {connectedUsers.slice(3).map((user: any) => (
                        <Dropdown.Item
                          key={user.id}
                          onClick={() => navigate(`/user/${user.id}`)}
                          className="d-flex align-items-center xs-regular"
                        >
                          <img
                            src={user.image_url ? user.image_url : "/assets/images/user.jpg"}
                            alt={`${user?.name}'s profile`}
                            className="rounded-circle object-fit-cover me-2 size-action-button border"
                          />
                          <span>{user.name}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <DstButton
                btnClass="rounded-circle light-gray-background border-0 me-2 p-2 m-regular action-button size-action-button"
                btnImageBefore={<i className="dst-icon-key-01 d-flex justify-content-center" />}
                value=""
                variant="secondary"
                clickFunction={() => alert("WIP")}
              />
              {evaluationInfos.evaluation?.type === "notebook" && (
                <DstButton
                  btnClass="rounded-circle light-gray-background border-0 me-2 p-2 m-regular action-button size-action-button"
                  btnImageBefore={<i className="dst-icon-download-02 d-flex justify-content-center" />}
                  loading={loading.downloadNotebook}
                  value=""
                  variant="secondary"
                  clickFunction={() =>
                    loading.downloadNotebook
                      ? null
                      : downloadEvaluationAttemptNotebook(language, eval_id, (newValue: boolean) =>
                          setLoading((loading) => ({ ...loading, file: newValue }))
                        )
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Row className="mx-0">
        <Col md lg={8} className="megium-gray-background p-2 scrollable" id="content-exam">
          {loading.render ? (
            <Spinner className="d-block blue-font" id="render-spinner" />
          ) : (
            (() => {
              switch (evaluationInfos.evaluation?.type) {
                case "html":
                  return (
                    <HtmlEvaluationRender
                      evaluationRender={evaluationRender}
                      evaluationInfos={evaluationInfos}
                      isCorrectionStarted={isCorrectionStarted}
                      handleStatusChange={handleStatusChange}
                      setTotalQuestions={setTotalQuestions}
                      setCorrectQuestions={setCorrectQuestions}
                      correctQuestions={correctQuestions}
                    />
                  );
                case "notebook":
                  return (
                    <iframe
                      title="evaluation content render"
                      srcDoc={evaluationRender}
                      className="white-background p-2 w-100 h-100"
                    />
                  );
                case "upload":
                  return (
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                      <strong className="mb-2">{translate(language, "PAGES.EVAL.EXAM_UPLOAD.TITLE")}</strong>
                      <DstButton
                        clickFunction={() => downloadEvaluationFileFromRender(evaluationRender)}
                        value={translate(language, "PAGES.EVAL.EXAM_UPLOAD.DOWNLOAD_BUTTON")}
                        btnImageBefore={<span className="dst-icon-file-download-03 pe-2 align-text-bottom" />}
                        btnSize="lg"
                        btnClass="s-bold"
                      />
                    </div>
                  );
                default:
                  return <p>Evaluation type {evaluationInfos.evaluation?.type} is not handled for the moment.</p>;
              }
            })()
          )}
        </Col>
        <Col md lg={4} className="s-regular d-flex flex-column px-0 justify-content-between" id="eval-info">
          {loading.infos ? (
            <div className="overflow-auto mb-2 px-4 py-3">
              <div className="mb-3">
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <div className="mt-2">
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={3} />
                  </Placeholder>
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="mt-2">
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={3} />
                  </Placeholder>
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="mt-2">
                  <Row className="mx-0">
                    <Col className="ps-0">
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </Col>
                    <Col className="pe-0">
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                      <Placeholder as="div" animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="mt-2">
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
              <Row>
                <Col className="mt-2">
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={3} />
                  </Placeholder>
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={5} />
                  </Placeholder>
                </Col>
                <Col className="mt-2">
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={3} />
                  </Placeholder>
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={5} />
                  </Placeholder>
                </Col>
              </Row>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={2} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <div className="d-flex border-bottom pb-2 align-items-center">
                  <div className="d-flex">
                    {[...Array(4)].map((_, index) => (
                      <Placeholder
                        key={index}
                        as="span"
                        animation="glow"
                        className="me-1 rounded"
                        style={{ width: "20px", height: "20px" }}
                      >
                        <Placeholder xs={12} />
                      </Placeholder>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
                <Row className="mx-0">
                  <Col className="ps-0">
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </Col>
                  <Col className="pe-0">
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </Col>
                </Row>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </div>
            </div>
          ) : (
            <div className="overflow-auto mb-2 px-4 py-3">
              <div className="mb-3">
                <h3> {translate(language, "PAGES.EVAL.USER_INFO.TITLE")}</h3>
                <div className="mt-2">
                  <div className="dark-gray-font">{translate(language, "PAGES.EVAL.USER_INFO.LEARNER_NAME")}</div>
                  <div className="d-flex justify-content-between border-bottom pb-2">
                    <div>
                      <i className="dst-icon-users-01 me-2" />
                      {evaluationInfos.user.name}
                      <span
                        className="clickable smoke-gray-font ms-2"
                        onClick={(event: React.MouseEvent) =>
                          completeNavigate(event, navigate, `/user/${evaluationInfos.user.id}`)
                        }
                      >
                        #{evaluationInfos.user.id}
                      </span>
                    </div>
                    <div>
                      {evaluationInfos.user.flags.includes("Top user") && (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top-user">Top User</Tooltip>}>
                          <i className="blue-font dst-icon-trophy-01 me-1"></i>
                        </OverlayTrigger>
                      )}
                      {evaluationInfos.user.flags.includes("VIP") && (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-vip">VIP</Tooltip>}>
                          <i className="blue-font dst-icon-star-01"></i>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="dark-gray-font">{translate(language, "PAGES.EVAL.USER_INFO.COHORT_NAME")}</div>
                  <div className="d-flex border-bottom pb-2">
                    <div>
                      <i className="dst-icon-users-01 me-2" />
                      {evaluationInfos.cohorts[0]?.cohort_name || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <Row className="mx-0">
                    <Col className="ps-0">
                      <div className="dark-gray-font">
                        {translate(language, "PAGES.EVAL.USER_INFO.PROGRAM_MANAGER")}
                      </div>
                      <div className="d-flex border-bottom pb-2">
                        <div>
                          <i className="dst-icon-users-01 me-2" />
                          {evaluationInfos.cohorts[0]?.program_manager || "N/A"}
                        </div>
                      </div>
                    </Col>
                    <Col className="pe-0">
                      <div className="dark-gray-font">{translate(language, "PAGES.EVAL.USER_INFO.COHORT_LEADER")} </div>
                      <div className="d-flex border-bottom pb-2">
                        <div>
                          <i className="dst-icon-users-01 me-2" />
                          {evaluationInfos.cohorts[0]?.cohort_leader || "N/A"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">{translate(language, "PAGES.EVAL.EXAM_INFO.TITLE")}</h3>
                <i className="dst-icon-flag-05 me-1 light-gray-background p-2 rounded-circle "></i>
              </div>
              <Row>
                <Col className="mt-2">
                  <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_STATUS")}</div>
                  <div className="border-bottom pb-2 d-flex align-items-center">
                    {getExamStatusIcon(evaluationInfos.status)}
                    <span className={`text-uppercase ${getStatusColor(evaluationInfos.status)}`}>
                      {evaluationInfos.status}
                    </span>
                  </div>
                </Col>
                <Col className="mt-2">
                  <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_SCORE")}</div>
                  <div className="d-flex border-bottom pb-2">
                    <div>
                      <i className="dst-icon-pencil-line me-2" />
                      {evaluationInfos.score === "None/None" ? "N/A" : evaluationInfos.score}
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mt-2">
                <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.AI_PREVISION_SCORE")}</div>
                <div className="d-flex border-bottom pb-2 orange-font">
                  <div>
                    <i className="dst-icon-atom-02 me-2" />
                    {evaluationInfos.ai_prevision_score}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.NUMBER_ATTEMPTS")}</div>
                <div className="d-flex border-bottom pb-2 align-items-center">
                  <i className="dst-icon-file-check-03 me-2" />

                  {[...Array(3)].map((_, attemptIndex) => {
                    const attempt = attemptIndex + 1;
                    let backgroundColorClass = "bg-light";

                    if (attempt === evaluationInfos.number_of_attempts) {
                      switch (attempt) {
                        case 1:
                          backgroundColorClass = "medium-green-background text-white";
                          break;
                        case 2:
                          backgroundColorClass = "orange-background text-white";
                          break;
                        case 3:
                          backgroundColorClass = "red-background text-white";
                          break;
                        default:
                          backgroundColorClass = "bg-light";
                      }
                    }

                    return (
                      <span key={attempt} className={`me-1 px-2 rounded ${backgroundColorClass}`}>
                        {attempt}
                      </span>
                    );
                  })}

                  {evaluationInfos.number_of_attempts > 3 && (
                    <span className="px-2 rounded electric-blue-background text-white">
                      {evaluationInfos.number_of_attempts}
                    </span>
                  )}
                </div>
              </div>
              {evaluationInfos.evaluation.type !== "upload" && (
                <div className="mt-2">
                  <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.DURATION")}</div>
                  <div className="d-flex border-bottom pb-2">
                    <div>
                      <div className="d-flex align-items-center">
                        <i className="dst-icon-clock-stopwatch me-2" />
                        <span className="me-2">
                          {formatSecondsMinutely(~~evaluationInfos.user_duration)} / {evaluationInfos.total_duration}min
                        </span>
                        <ProgressBar
                          id="duration-eval"
                          className="light-blue-background"
                          now={(evaluationInfos.user_duration / 60 / evaluationInfos.total_duration) * 100}
                          label={
                            evaluationInfos.user_duration / 60 / evaluationInfos.total_duration >= 0.2
                              ? `${(
                                  (evaluationInfos.user_duration / 60 / evaluationInfos.total_duration) *
                                  100
                                ).toFixed(0)}%`
                              : ""
                          }
                          style={{
                            width: "150px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-2">
                <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.REMARK.TITLE")}</div>
                <div className="d-flex flex-column border-bottom pb-2">
                  <div className="mb-2">
                    {!showFullComment ? truncateString(comment) : comment}
                    {comment.length > 100 && (
                      <button
                        onClick={() => setShowFullComment((prev) => !prev)}
                        className="btn btn-link p-0 ms-1 mb-1 xs-regular"
                      >
                        {showFullComment
                          ? translate(language, "PAGES.EVAL.EXAM_INFO.REMARK.SHOW_LESS")
                          : translate(language, "PAGES.EVAL.EXAM_INFO.REMARK.SHOW_MORE")}
                      </button>
                    )}
                  </div>
                  <Row className="mx-0">
                    <Col className="ps-0">
                      <div className="dark-gray-font">
                        {translate(language, "PAGES.EVAL.EXAM_INFO.REMARK.DIFFICULTY_RATE")}
                      </div>
                      {!!evaluationInfos.user?.remark?.difficulty_rate ? (
                        <StarsRating
                          value={evaluationInfos.user.remark.difficulty_rate / 2}
                          count={5}
                          disabled
                          classNamePrefix="custom-rating"
                        />
                      ) : (
                        <span>-</span>
                      )}
                    </Col>
                    <Col className="pe-0">
                      <div className="dark-gray-font">
                        {translate(language, "PAGES.EVAL.EXAM_INFO.REMARK.SELF_RATE")}
                      </div>
                      {!!evaluationInfos.user?.remark?.self_rate ? (
                        <StarsRating
                          value={evaluationInfos.user.remark.self_rate / 2}
                          count={5}
                          disabled
                          classNamePrefix="custom-rating"
                        />
                      ) : (
                        <span>-</span>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="mt-2">
                <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.TITLE")}</div>
                <div className="d-flex border-bottom py-2">
                  <div>
                    <i className="dst-icon-mail-02 me-2" />
                    {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.SENDING_DATE")}
                    <span className="dark-gray-font ms-3">
                      {evaluationInfos.dt_sent ? formatDateHumanly(language, evaluationInfos.dt_sent, true) : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="d-flex border-bottom py-2">
                  <div>
                    <i className="dst-icon-mail-04 me-2" />
                    {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.OPENING_DATE")}
                    <span className="dark-gray-font ms-3">
                      {evaluationInfos.dt_mail_opened
                        ? formatDateHumanly(language, evaluationInfos.dt_mail_opened, true)
                        : "N/A"}
                    </span>
                  </div>
                </div>
                {evaluationInfos.evaluation.type !== "upload" && (
                  <React.Fragment>
                    <div className="d-flex border-bottom py-2">
                      <div>
                        <i className="dst-icon-play me-2" />
                        {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.START_DATE")}
                        <span className="dark-gray-font ms-3">
                          {evaluationInfos.dt_start
                            ? formatDateHumanly(language, evaluationInfos.dt_start, true)
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex border-bottom py-2">
                      <div>
                        <i className="dst-icon-check me-2" />
                        {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.END_DATE")}
                        <span className="dark-gray-font ms-3">
                          {evaluationInfos.dt_ended
                            ? formatDateHumanly(language, evaluationInfos.dt_ended, true)
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {evaluationInfos.evaluation.type === "notebook" && (
                  <div className="d-flex border-bottom py-2">
                    <div>
                      <i className="dst-icon-fast-backward me-2" />
                      <a href="https://google.com/">
                        {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.BACKUPS")}
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-2">
                <div className="dark-gray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_NAME")}</div>
                <div className="d-flex border-bottom py-2">
                  <div>
                    <i className="dst-icon-file-01 me-2" />
                    <span className="ms-3">{evaluationInfos.evaluation.filename}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loading.infos ? (
            <div className="d-grid px-4">
              <DstButton mock />
            </div>
          ) : (
            !isCorrectionStarted && (
              <div className="d-grid px-4">
                <DstButton
                  value={
                    ["success", "repass", "failed"].includes(evaluationInfos.status)
                      ? `${translate(language, "PAGES.EVAL.CORRECTED_BY")} ${evaluationInfos.corrector?.name}`
                      : hasAlreadyCorrector
                      ? `${translate(language, "PAGES.EVAL.IN_CORRECTION_BY")} ${correctorName}`
                      : translate(language, "PAGES.EVAL.START_CORRECTION")
                  }
                  btnImageBefore={<i className="dst-icon-play me-2" />}
                  clickFunction={() => startCorrection(eval_id, handleShowOverlay, language)}
                  disabled={
                    hasAlreadyCorrector ||
                    ["success", "repass", "failed", "being_corrected", "pre_correction_in_progress"].includes(
                      evaluationInfos.status
                    )
                  }
                />
              </div>
            )
          )}
          {showOverlay && (
            <div id="overlay-exam" className="white-background p-3 border">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="mb-0">{translate(language, "PAGES.EVAL.REVIEW.TITLE")}</h3>
                <i
                  className={`clickable p-2 off-white-background rounded-circle ${
                    isMinimized ? "dst-icon-chevron-up" : "dst-icon-chevron-down"
                  }`}
                  onClick={() => setIsMinimized(!isMinimized)}
                  aria-label="Minimize"
                />
              </div>
              {!isMinimized && (
                <div className="mt-2">
                  <div id="editor-feedback-exam">
                    {evaluationInfos.ai_comment && !isModifiedComment && (
                      <div className="p-1 rounded light-orange-background mt-2 d-flex align-items-center">
                        <i className="fs-6 orange-font dst-icon-atom-02 me-2" />
                        <span className="fs-6 orange-font">{translate(language, "PAGES.EVAL.REVIEW.AI_REMARK")}</span>
                      </div>
                    )}
                    <ReactQuill
                      value={editorContent}
                      onChange={setEditorContent}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "blockquote"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link" /* "image" */],
                          ["code-block"],
                        ],
                      }}
                      placeholder={translate(language, "PAGES.EVAL.REVIEW.PLACEHOLDER")}
                    />
                  </div>
                  <div className="p-1 rounded light-orange-background mt-2 xs-regular">
                    <i className="dst-icon-lightbulb-02 me-2" />
                    {translate(language, "PAGES.EVAL.REVIEW.REMEMBER_LANGUAGE").replace(
                      "{{LANGUAGE}}",
                      evaluationInfos.user.language
                    )}{" "}
                    <ReactCountryFlag
                      svg
                      countryCode={evaluationInfos.user.language.split("-")[1]}
                      title={evaluationInfos.user.language}
                      className="pb-1 h-auto"
                    />
                  </div>
                  <Form.Group controlId="scoreInput" className="mt-3 d-flex align-items-center">
                    <Form.Label className="me-2 mb-0 dark-gray-font">
                      {translate(language, "PAGES.EVAL.REVIEW.EVALUATION_SCORE").replace(
                        "{{SCORE_BASE}}",
                        String(score_base)
                      )}
                    </Form.Label>
                    <Form.Control
                      className="s-regular"
                      type="number"
                      placeholder={translate(language, "PAGES.EVAL.REVIEW.PLACEHOLDER_SCORE").replace(
                        "{{SCORE_BASE}}",
                        String(score_base)
                      )}
                      value={score !== undefined ? score : ""}
                      onChange={(event) => {
                        const input = event.target.value.replace(",", ".");
                        const enteredScore = parseFloat(input);
                        setScore(
                          input === ""
                            ? undefined
                            : !isNaN(enteredScore) && enteredScore >= 0
                            ? Math.min(enteredScore, score_base)
                            : score
                        );
                      }}
                      onKeyDown={(event) => {
                        if (["e", "E", "+", "-"].includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-2">
                    <ExamStatusDropdown onStatusChange={(status) => setExamStatus(status)} initialStatus={examStatus} />
                    <DstButton
                      btnClass="mt-2"
                      value={translate(language, "PAGES.EVAL.BUTTONS.SEND_CORRECTION")}
                      btnSize="lg"
                      btnImageBefore={<i className="dst-icon-send-03 me-1" />}
                      loading={loading.submit}
                      disabled={
                        loading.submit ||
                        (evaluationInfos.evaluation.type === "html" && !isAllCorrected) ||
                        (evaluationInfos.evaluation.type !== "html" &&
                          (examStatus === "" || examStatus === "—" || isAllCorrected))
                      }
                      clickFunction={() => {
                        setLoading((prev) => ({ ...prev, submit: true }));
                        sendCorrection(
                          language,
                          evaluationInfos.id,
                          editorContent,
                          examStatus,
                          score,
                          score_base,
                          answersCorrection,
                          () => {
                            setLoading((prev) => ({ ...prev, infos: true }));
                            ApiService.get(`evaluations/attempts/${eval_id}/details`)
                              .then((resp) => setEvaluationInfos(resp.data.evaluation_attempt_details))
                              .catch((err) => console.error(err))
                              .finally(() => {
                                setIsCorrectionStarted(false);
                                setShowOverlay(false);
                                setLoading((loading) => ({ ...loading, infos: false }));
                              });
                          },
                          () => setLoading((prev) => ({ ...prev, submit: false }))
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Correction;
